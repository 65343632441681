import React from "react"
import { StaticQuery, graphql } from "gatsby"
import "what-input"

import Header from "./header"
import Footer from "./footer"
import "../styles/components/layout.scss"

class Layout extends React.Component {
  constructor(props) {
    super(props)

    this.state = { isMobile: false }

    this.links = this.props.data.site.siteMetadata.links
  }

  componentDidMount() {
    this.handleResize()
    window.addEventListener("resize", this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize)
  }

  handleResize = () => {
    if (window.innerWidth > 768 && this.state.isMobile) {
      this.setState({ isMobile: false })
    } else if (window.innerWidth <= 768 && !this.state.isMobile) {
      this.setState({ isMobile: true })
    }
  }

  render() {
    return (
      <>
        <Header links={this.links} isMobile={this.state.isMobile} />
        <div
          style={{
            margin: `0 auto`,
            paddingTop: "69px",
            zIndex: -1,
          }}
        >
          <main>{this.props.children}</main>
        </div>
        <Footer links={this.links} isMobile={this.state.isMobile} />
      </>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            links {
              main {
                to
                text
              }
              outbound {
                to
                text
              }
              other {
                to
                text
              }
            }
          }
        }
      }
    `}
    render={data => <Layout data={data} {...props} />}
  />
)
