import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

import FlybridgeLogo from "../images/flybridge-logo.svg"
import IconLinkArrow from "../images/icon-link-arrow.svg"
import styles from "../styles/components/footer.module.scss"

import classNames from "classnames/bind"
import { renderedLink } from "../utils/links"
const cx = classNames.bind(styles)

class Footer extends React.Component {
  render() {
    const addresses = this.props.data.allContentfulFooter.edges[0].node
      .addresses

    return (
      <>
        {this.props.isMobile && (
          <footer className={styles.container}>
            <Link to="/" className={styles.logo} aria-label="Home">
              <FlybridgeLogo />
            </Link>
            <div className={styles.linksContainer}>
              <ul className={styles.linksSeparator}>
                {this.props.links.main.map((link, key) => (
                  <li key={key} className={styles.link}>
                    {renderedLink(link)}
                  </li>
                ))}
                <li className={styles.link}>
                  <a
                    className={styles.email}
                    href="mailto:hello@flybridge.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    hello@flybridge.com
                  </a>
                </li>
              </ul>
              <ul className={styles.linksSeparator}>
                {this.props.links.outbound.map((link, key) => (
                  <li key={key} className={cx("link", "outbound")}>
                    <a href={link.to} target="_blank" rel="noopener noreferrer">
                      {link.text}
                    </a>
                    <IconLinkArrow />
                  </li>
                ))}
              </ul>
              <ul className={styles.linksSeparator}>
                {this.props.links.other.map((link, key) => (
                  <li key={key} className={styles.link}>
                    <a href={link.to} target="_blank" rel="noopener noreferrer">
                      {link.text}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className={styles.addressContainer}>
              {addresses.map((address, i) => {
                return (
                  <a
                    href={address.googleMapsLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.address}
                    key={i}
                  >
                    <div className={styles.city}>{address.city}</div>
                    <div>{address.address.address}</div>
                  </a>
                )
              })}
            </div>
            <div className={styles.legalContainer}>
              <div className={styles.copyright}>
                © 2020 Flybridge Capital Partners. All Rights Reserved.
              </div>
              <a
                className={styles.WBCG}
                href="https://www.westonbaker.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Designed by Weston Baker Creative Group
              </a>
              <div className={styles.policies}>
                <Link to="/privacy-notice">Privacy Policy</Link>
                {/* <Link to="/">Terms of Use</Link> */}
              </div>
            </div>
          </footer>
        )}
        {!this.props.isMobile && (
          <footer className={styles.desktopContainer}>
            <div className={styles.topSection}>
              <div>
                <Link to="/" className={styles.logo} aria-label="Home">
                  <FlybridgeLogo />
                </Link>
              </div>
              <div className={styles.linksContainer}>
                <ul className={styles.linksSeparator}>
                  {this.props.links.main.map((link, key) => (
                    <li key={key} className={styles.link}>
                      {renderedLink(link)}
                    </li>
                  ))}
                  <li className={styles.link}>
                    <a
                      className={styles.email}
                      href="mailto:hello@flybridge.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      hello@flybridge.com
                    </a>
                  </li>
                  {this.props.links.outbound.map((link, key) => (
                    <li key={key} className={cx("link", "outbound")}>
                      <a
                        href={link.to}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {link.text}
                      </a>
                      <IconLinkArrow />
                    </li>
                  ))}
                </ul>
                <ul className={styles.linksSeparator}>
                  {this.props.links.other.map((link, key) => (
                    <li key={key} className={styles.link}>
                      <a
                        href={link.to}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {link.text}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className={styles.addressContainer}>
                {addresses.map((address, i) => {
                  return (
                    <a
                      href={address.googleMapsLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.address}
                      key={i}
                    >
                      <div className={styles.city}>{address.city}</div>
                      <div>{address.address.address}</div>
                    </a>
                  )
                })}
                {/* <a
                  href="https://goo.gl/maps/EzDdKusAjDA7MLvx5"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.address}
                >
                  <div className={styles.city}>Boston</div>
                  <div>
                    31 St. James Avenue, 6th Floor <br />
                    Boston, MA 02116
                  </div>
                </a>
                <a
                  href="https://goo.gl/maps/HDKDom6ef1ouXMpD9"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.address}
                >
                  <div className={styles.city}>New York City</div>
                  <div>
                    79 Madison Avenue <br /> New York, NY 10016
                  </div>
                </a> */}
              </div>
            </div>
            <div className={styles.legalContainer}>
              <div className={styles.copyright}>
                © 2020 Flybridge Capital Partners. All Rights Reserved.
                <a
                  className={styles.WBCG}
                  href="https://www.westonbaker.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Designed by Weston Baker Creative Group
                </a>
              </div>

              <div className={styles.policies}>
                <Link to="/privacy-notice/">Privacy Policy</Link>
                {/* <Link to="/">Terms of Use</Link> */}
              </div>
            </div>
          </footer>
        )}
      </>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulFooter {
          edges {
            node {
              addresses {
                city
                address {
                  address
                }
                googleMapsLink
              }
            }
          }
        }
      }
    `}
    render={data => <Footer data={data} {...props} />}
  />
)
