import { Link } from "gatsby"
import React from "react"
import { CSSTransition } from "react-transition-group"
import MobileMenu from "./mobileMenu"
import withLocation from "./withLocation"

import FlybridgeLogo from "../images/flybridge-logo-updated.svg"
import IconHamburger from "../images/icon-hamburger.svg"
import IconLinkArrow from "../images/icon-link-arrow.svg"
import styles from "../styles/components/header.module.scss"
import { renderedLink } from "../utils/links"

import classNames from "classnames/bind"
const cx = classNames.bind(styles)

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menuOpen: false,
      headerVisible: true,
    }
    this.didScroll = true
    this.prevScroll = 0
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
  }

  handleScroll = () => {
    let scroll = window.scrollY
    if (scroll < 69) {
      this.setState({ headerVisible: true })
    } else if (this.prevScroll < scroll && this.state.headerVisible) {
      //down
      this.setState({ headerVisible: false })
    } else if (this.prevScroll > scroll && !this.state.headerVisible) {
      //up
      this.setState({ headerVisible: true })
    }
    this.prevScroll = scroll
  }

  openMenu = () => {
    window.removeEventListener("scroll", this.handleScroll)
    if (typeof document !== "undefined") {
      let html = document.querySelector("html")
      this.scrollTop = -html.getBoundingClientRect().top
      html.style.top = `${html.getBoundingClientRect().top}px`
      html.classList.add(styles.preventBodyScroll)
    }

    this.setState({ menuOpen: true })
  }

  closeMenu = () => {
    if (typeof document !== "undefined") {
      let html = document.querySelector("html")
      html.classList.remove(styles.preventBodyScroll)
      html.scrollTop = this.scrollTop
    }

    this.setState({ menuOpen: false, headerVisible: true })
    window.addEventListener("scroll", this.handleScroll)
  }

  render() {
    return (
      <>
        <header
          className={cx("container", {
            noShadow: this.props.location.pathname === "/our-community",
            visible: this.state.headerVisible,
          })}
        >
          <div className={styles.subcontainer}>
            <Link to="/" className={styles.logo} aria-label="Home">
              <FlybridgeLogo />
            </Link>
            {this.props.isMobile ? (
              <button
                className={styles.hamburger}
                onClick={this.openMenu}
                aria-label="Open Menu"
              >
                <IconHamburger />
              </button>
            ) : (
              <nav>
                <ul className={styles.linksContainer}>
                  {this.props.links.main.map((link, key) => (
                    <li key={key} className={styles.link}>
                      {renderedLink(link, styles.active)}
                    </li>
                  ))}
                  {this.props.links.outbound.map((link, key) => (
                    <li key={key} className={cx("link", "outbound")}>
                      <a
                        href={link.to}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {link.text}
                      </a>
                      <IconLinkArrow />
                    </li>
                  ))}
                </ul>
              </nav>
            )}
          </div>
        </header>
        <CSSTransition
          in={this.state.menuOpen}
          mountOnEnter
          //unmountOnExit
          timeout={200}
          classNames="menu"
        >
          <MobileMenu links={this.props.links} closeMenu={this.closeMenu} />
        </CSSTransition>
      </>
    )
  }
}

export default withLocation(Header)
