import { Link } from "gatsby"
import React from "react"

import styles from "../styles/components/mobile-menu.module.scss"
import FlybridgeLogo from "../images/flybridge-logo.svg"
import IconClose from "../images/icon-close.svg"
import IconLinkArrow from "../images/icon-link-arrow.svg"

import classNames from "classnames/bind"
const cx = classNames.bind(styles)

class MobileMenu extends React.Component {
  render() {
    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.logo}>
            <FlybridgeLogo />
          </div>
          <button
            className={styles.closeButton}
            onClick={this.props.closeMenu}
            aria-label="Close Menu"
          >
            <IconClose />
          </button>
        </div>
        <ul className={styles.linksContainer}>
          {this.props.links.main.map((link, i) => (
            <li className={styles.link} key={i}>
              <Link to={link.to} onClick={this.props.closeMenu}>
                {link.text}
              </Link>
            </li>
          ))}
          {this.props.links.outbound.map((link, i) => (
            <li className={cx("link", "outbound")} key={i}>
              <a href={link.to} target="_blank" rel="noopener noreferrer">
                {link.text}
              </a>
              <IconLinkArrow />
            </li>
          ))}
        </ul>
      </div>
    )
  }
}

export default MobileMenu
