import React from "react"
import { Link } from "gatsby"

export const renderedLink = (link, activeClassName) => {
  return (
    <Link to={link.to} activeClassName={activeClassName}>
      {link.text}
    </Link>
  )
}
